import React from "react";

function Services() {
  const elements = [
    {
      id: 1,
      name: "Quality products",
      content:
        "All of our bags are made from high-quality materials that are designed to last. We carefully select our products to ensure that they are durable, functional, and stylish.",
    },
    {
      id: 2,
      name: "Wide selection",
      content:
        "We offer a wide range of bags to suit every style and need. Whether you're looking for a sleek leather tote or a practical backpack for your daily commute, we've got you covered.",
    },
    {
      id: 3,
      name: "Competitive prices",
      content:
        "We believe that everyone should have access to high-quality bags at affordable prices. That's why we offer our products at competitive prices without compromising on quality",
    },
    {
      id: 4,
      name: "Excellent customer service",
      content:
        "We're dedicated to providing our customers with the best possible shopping experience. Our friendly and knowledgeable staff are always available to help you find the perfect bag for your needs.",
    },
  ];
  return (
    <section id="services" className="font-ques py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
        <h1 className="max-w-2xl mb-4 text-4xl font-bold uppercase tracking-tight leading-none md:text-5xl py-2 md:py-8">
          Our Services
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 space-y-6 md:space-y-0">
          {elements.map(function (element) {
            const { id, name, content } = element;
            return (
              <div key={id} className="flex flex-col space-y-4 justify-center p-4 rounded-xl border-2 hover:border-main shadow-lg transition">
                <p className="text-xl font-semibold capitalize">{name}</p>
                <p>
                  {content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
