import React from "react";

function Footer() {
  return (
    <section className="font-ques bg-black">
      <footer className="container mx-auto p-4 rounded-lg md:flex md:items-center md:justify-between md:p-6">
        <span className="text-sm text-white sm:text-center">
          © 2023
          <a href="" className="hover:underline">
            Company
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm text-white sm:mt-0">
          <li>
            <a href="" className="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="" className="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="" className="mr-4 hover:underline md:mr-6">
              Licensing
            </a>
          </li>
          <li>
            <a href="" className="hover:underline">
              Contact
            </a>
          </li>
        </ul>
      </footer>
    </section>
  );
}

export default Footer;
