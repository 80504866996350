import React from "react";
// ------------------------------- Component Contents -------------------------------
const heading = "Find Your Perfect Bag";
const subHeading = "Shop Branded, Fancy, Tourist Bags & More at our Bag Shop";
const cta = "Get in Touch";
// ------------------------------- Component Contents Ends-------------------------------
function Hero() {
  return (
    <section id="hero" className="px-3 md:px-0">
      <div className="font-ques relative bg-white pt-[20px] pb-[50px] lg:pt-[100px]">
        <div className="container mx-auto">
          <div className="flex items-center justify-center flex-col-reverse md:flex-row">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content pt-20 md:pt-0">
                <h1 className="text-dark mb-3 text-2xl md:text-4xl font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[42px]">
                  {heading}
                </h1>
                <p className="text-body-color text-md md:text-2xl mb-8 max-w-[480px]">
                  {subHeading}
                </p>
                <ul className="flex flex-wrap items-center">
                  <li>
                    <a href="#contact" className="bg-black inline-flex items-center justify-center rounded-lg py-4 px-6 text-center text-lg text-white hover:bg-opacity-90 sm:px-10 lg:px-8 xl:px-10">
                      {cta}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <div className="relative inline-block pt-11 lg:pt-0">
                  <img
                    src={process.env.PUBLIC_URL + "assets/hero.png"}
                    alt="hero"
                    className="max-w-xs md:max-w-xl lg:ml-auto rounded-xl"
                  />
                  <span className="absolute -left-8 -bottom-8 z-[-1]">
                    <svg
                      width="93"
                      height="93"
                      viewBox="0 0 93 93"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="90.5" r="2.5" fill="#3056D3" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
