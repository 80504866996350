import React from "react";
const elements = [
  { id: 1, name: "product 1", price: 100, image: "1" },
  { id: 2, name: "product 2", price: 200, image: "2" },
  { id: 3, name: "product 3", price: 300, image: "3" },
  { id: 4, name: "product 4", price: 400, image: "4" },
  { id: 5, name: "product 5", price: 500, image: "5" },
  { id: 6, name: "product 6", price: 600, image: "6" },
  { id: 7, name: "product 7", price: 700, image: "7" },
  { id: 8, name: "product 8", price: 800, image: "8" },
  { id: 9, name: "product 9", price: 900, image: "9" },
  { id: 10, name: "product 10", price: 1000, image: "10" },
  { id: 11, name: "product 11", price: 1100, image: "11" },
  { id: 12, name: "product 12", price: 1200, image: "12" },
];
function Products() {
  return (
    <section id="products" className="font-ques py-2 md:py-16 container mx-auto">
      <h1 className="pb-8 text-3xl md:text-5xl capitalize font-bold text-center md:text-left">
        our products
      </h1>
      <div className="grid grid-cols-1 place-items-center sm:grid-cols-2 md:grid-cols-4 gap-4 px-2 md:px-0">
        {elements.map(function (el) {
          return (
            <div key={el.id} className="card object-cover pb-6">
              <div className="card__image">
                <img
                  src={process.env.PUBLIC_URL + `assets/bags/${el.image}.png`}
                  alt="product"
                  className={el.id <= 6 ? "h-[200px] w-[300px] object-cover rounded" : "rounded md:block h-[200px] w-[300px] object-cover hidden"}                  
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Products;
